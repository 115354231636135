<template>
	<div class="">

  	<div id="logIn" ref="logIn" @keydown.esc="goHome"  @keydown.enter="checkUser">
  		<div class="modalBg"></div>
  		<div class="modalDialog">
  			<div class="header">
  				Bitte mit Benuntzername und Password anmelden.
  			</div>
  			<div class="body">
  				<div class="cont">
  					<input ref="user" type="text" v-model="user.username" placeholder="benutzername">
  					<input type="password" v-model="user.pw" placeholder="password" @keydown.enter="checkUser">
  				</div>
  				<div class="buttons">
  					<div class="btn2" @click="goHome">Abbrechen</div>
  					<div class="btn1" @click="checkUser">OK</div>
  				</div>
  			</div>
  		</div>
  	</div>

	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'LogIn',
		components: {
			// dialogComp,
		},
		mounted(){
			this.$refs.user.focus();
			this.tryLogIn();



		}, // end mounted 
		data: 
		function(){
			return {
				user: {username: '', pw:''}
			}
		}, // end data
		methods: {
			checkUser(){

				let self = this;

				setTimeout(function(){

					let ok = false;
					if (self.user.username === '' || self.user.pw === '') {
						alert("Bitte Benuntzername und Password eingeben!");
						return false;
					}

					for (const v  of self.$store.state.users ){

						if (v.username === self.user.username && v.pw === self.user.pw) {

							self.logIn();
							location.replace('/');

							ok = true;
						} 
					}


					if (! ok) {
						alert("Benutzername oder PW unbekannt");
						return false;
					}
				},1000, self);



			},
			logOut(){
				document.cookie = '-viridarium=a12342ddfll; max-age=0;'
				this.$router.go('/');

			},
			logIn(){

				document.cookie = '-viridarium=a12342ddfll; max-age=2147483647;'

			},
			goHome(){
				this.$router.replace('/');
			},
			tryLogIn(){

				let goodQuery = '/bearbeiten-' + new Date().getDate();

				if (this.$route.path === goodQuery){

					return;
				} else {
					this.$router.replace('/');
				}
			}


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">
 #logIn {
	display: block;
	height: 100%;
	width: 100%;

	& .modalBg {
		height: 100%;
		width: 100%;
		position: fixed;
		background-color: #000;
		opacity: .3;
		z-index: 100;
		left: 0;
		top: 0;
	}
	& .modalDialog {
		padding: 1rem;
		background-color: white;
		z-index: 101;
		width: 30rem;
		height: 20%;
		position: absolute;
		left: 25%;
		top: 16%;
		display: flex;
		flex-direction: column;
		border-radius: 6px;
		box-shadow: 2px 2px 4px #757575;

		& .header {
			padding: 1rem;
			border-radius: 6px;
			background-color: #eee;
		}
		& .body {
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: left;

			height: 100%;
			& div.buttons {
				margin-top: 1rem;
				display: flex;
				justify-content: space-around;

				& div {
					border: 1px solid silver;
					padding: .5rem 2rem;
					border-radius: 4px;
					cursor: pointer;
					&:hover {
						background-color: gray;
						color: white;
					}
				}
			} 
		}
	}
}

</style>
